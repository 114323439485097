import ReactPlayer from 'react-player';

export default function TropecarPorFavor() {
  return (
    <div className="text-center">
      <h1 className="text-golden text-4xl py-20">Tropecar Por Favor! (Stumble Please!)</h1>

      <div className="flex flex-col leading-relaxed items-center">
        <p className="leading-relaxed pt-20">Description of the project</p>
        <p className="leading-relaxed p-20 text-2xl xl:w-2/3 lg:w-full sm:w-full">
          Tropeçar, por favor! is a project that offers artistic residencies with heterogeneous and diverse casts,
          bringing together dance professionals and groups of individuals with some type of physical, social, or
          cognitive disadvantage to co-create a choreographic piece.
        </p>
        <p>
          Over the course of two weeks, Sublime's artistic team collaborates with a selected institution or group of
          individuals to explore the full creative potential of the body. The project also aims to provide
          expressive-body and choreographic composition tools to healthcare and education professionals who work
          regularly and closely with these populations.
        </p>
        <ReactPlayer url="https://www.youtube.com/watch?v=ehjXqbdhsh4" controls className="py-10" />
      </div>
    </div>
  );
}
