export const repertoryData = [
  {
    title: 'PUSH IT ONLINE',
    date: '2021',
    img: 'https://res.cloudinary.com/umuv/image/upload/v1641482709/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/push_it_online_2.00_00_10_05.Still007.jpg',
    link_to: 'push-it-online',
  },
  {
    title: 'KUTXI KUTXI KUTXI',
    date: '2020',
    img: 'https://res.cloudinary.com/umuv/image/upload/v1664705172/Sublime%20Dance%20Company/REPERTORY/Kutxi%20Kutxi/kutxi-poster.jpg',
    link_to: 'kutxi-kutxi-kutxi',
  },
  {
    title: 'STEP FALL RISE',
    date: '2022',
    img: 'https://res.cloudinary.com/umuv/image/upload/v1645574316/Sublime%20Dance%20Company/REPERTORY/StepFallRise/sfr_main_final.webp',
    link_to: 'step-fall-rise',
  },
  {
    title: 'THE FLUIDITY OF THINGS',
    date: '2019',
    img: 'https://res.cloudinary.com/umuv/image/upload/v1664707238/Sublime%20Dance%20Company/REPERTORY/The%20Fluidity%20Of%20Things/FOT-card-poster-web.jpg',
    link_to: 'the-fluidity-of-things',
  },
  {
    title: 'PARTE DE COISA NENHUMA',
    date: '2017',
    img: 'https://res.cloudinary.com/umuv/image/upload/v1664755891/Sublime%20Dance%20Company/REPERTORY/Parte%20De%20Coisa%20Nenhuma/parte-de-coisa-nenhuma-poster.png',
    link_to: 'parte-de-coisa-nenhuma',
  },
  {
    title: '64x POSSIBILITIES',
    date: '2014',
    img: 'https://res.cloudinary.com/umuv/image/upload/v1665444795/Sublime%20Dance%20Company/REPERTORY/64x%20Possibilities/64x_3.jpg ',
    link_to: '64x-possibilities',
  },
  {
    title: 'Quem Roubou o Natal?',
    date: '2024',
    img: 'https://res.cloudinary.com/umuv/image/upload/v1738491407/Sublime%20Dance%20Company/REPERTORY/Who%20Stole%20Christmas/20241206_OVN_mary_44.jpg',
    link_to: 'quem-roubou-o-natal',
  },
  {
    title: 'Tropeçar, por favor!',
    date: '2024',
    img: 'https://res.cloudinary.com/umuv/image/upload/v1738497993/Sublime%20Dance%20Company/REPERTORY/Tropecar%20Por%20Favor/Screen_Shot_2025-02-02_at_12.05.08_PM.png',
    link_to: 'tropecar-por-favor',
  },
];
