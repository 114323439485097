export const events2024 = [
  {
    what: 'Quem Roubou o Natal?',
    when: 'December 2024 - January 2025',
    where: 'Óbidos',
    about: 'Financing and Partners: Município de Óbidos, Óbidos Criativa',
    links: [
      { eventLink: '' },
      { ticketLink: '' },
      { videoLink: 'https://www.youtube.com/shorts/VnH0tRX6sZU' },
      { facebookLink: '' },
      { umuvLink: '' },
    ],
  },
  {
    what: 'Talk: Movimento e Saúde Mental (Movement and Mental Health)',
    when: 'October',
    where: 'Coimbra',
    about: 'Financing and Partners: Festival Mate',
    links: [{ eventLink: '' }, { ticketLink: '' }, { videoLink: '' }, { facebookLink: '' }, { umuvLink: '' }],
  },
  {
    what: 'Artistic Residency: Tropeçar, por favor!',
    when: 'September',
    where: 'Anadia',
    about:
      'Financing and Partners: Dgartes, Município de Anadia APPACDM Anadia, Cercipom, Clube de Ancas, Sítio Azul Dança',
    links: [
      {
        eventLink:
          'https://www.appacdmanadia.pt/novidades-das-respostas-sociais/noticia-16-residencia-artistica-tropecar-por-favor/',
      },
      { ticketLink: '' },
      { videoLink: 'https://www.youtube.com/watch?v=ehjXqbdhsh4' },
      { facebookLink: '' },
      { umuvLink: '' },
    ],
  },
  {
    what: 'Creation of a new piece by Diana Seabra',
    when: 'July',
    where: 'Studio',
    about: 'A new piece by Diana Seabra',
    links: [{ eventLink: '' }, { ticketLink: '' }, { videoLink: '' }, { facebookLink: '' }, { umuvLink: '' }],
  },
  {
    what: 'Artistic Residency at Jobra',
    when: 'June',
    where: 'Jobra - Escola Profissional das Artes Performativas in Branca',
    about: ' Artistic Residency at Jobra - Escola Profissional das Artes Performativas in Branca, Albergaria-a-Velha',
    links: [{ eventLink: '' }, { ticketLink: '' }, { videoLink: '' }, { facebookLink: '' }, { umuvLink: '' }],
  },
  {
    what: 'Step Fall and Rise excerpt for Oeiras Community Valley',
    when: 'March 14',
    where: 'Oeiras Community Valley',
    about: ' Step Fall and Rise excerpt for Oeiras Community Valley, Portugal',
    links: [{ eventLink: '' }, { ticketLink: '' }, { videoLink: '' }, { facebookLink: '' }, { umuvLink: '' }],
  },
  {
    what: 'The Valley - piece for Oeiras Community Valley',
    when: 'March 14',
    where: 'Oeiras Community Valley',
    about: ' The Valley - piece for Oeiras Community Valley, Portugal',
    links: [{ eventLink: '' }, { ticketLink: '' }, { videoLink: '' }, { facebookLink: '' }, { umuvLink: '' }],
  },
];

export const events2023 = [
  {
    what: 'Workshop: Dinâmicas de Grupo através de movimento',
    when: '21 de Abril 2023',
    where: 'Fábrica da Pólvora de Barcarena',
    about: 'Evento de Team Building para PHC Software',
    links: [{ eventLink: '' }, { ticketLink: '' }, { videoLink: '' }, { facebookLink: '' }, { umuvLink: '' }],
  },
  {
    what: 'Workshop: Dinâmicas de Grupo através de movimento',
    when: '21 de Junho 2023',
    where: 'Fábrica da Pólvora de Barcarena',
    about: 'Evento de Team Building para PHC Software',
    links: [{ eventLink: '' }, { ticketLink: '' }, { videoLink: '' }, { facebookLink: '' }, { umuvLink: '' }],
  },
  {
    what: 'Talk/Workshop: Eu, o meu corpo e a minha história',
    when: '5 de Setembro 2023',
    where: 'Royal Óbidos Spa & Golf Resort',
    about: 'Evento de Team Building para VIATRIS',
    links: [{ eventLink: '' }, { ticketLink: '' }, { videoLink: '' }, { facebookLink: '' }, { umuvLink: '' }],
  },
  {
    what: 'Push it Online',
    when: '30 de Setembro 2023 @ 21:30',
    where: 'Auditório Eunice Muñoz, Oeiras',
    about: '',
    links: [{ eventLink: '' }, { ticketLink: '' }, { videoLink: '' }, { facebookLink: '' }, { umuvLink: '' }],
  },
  {
    what: 'Push it Online',
    when: '28 de Outubro 2023',
    where: 'CACAU - Casa das Artes Criação Ambiente Utopia, São Tomé e Príncipe',
    about: '',
    links: [{ eventLink: '' }, { ticketLink: '' }, { videoLink: '' }, { facebookLink: '' }, { umuvLink: '' }],
  },
  {
    what: 'Push it Online',
    when: '12-16 de Dezembro 2023',
    where: 'Auditório Nacional Jorge Barbosa, Praia, Cabo Verde',
    about: '',
    links: [{ eventLink: '' }, { ticketLink: '' }, { videoLink: '' }, { facebookLink: '' }, { umuvLink: '' }],
  },
];

export const events = [
  {
    what: 'PUSH IT ONLINE',
    when: 'December 3, 2022',
    where: 'Teatro Municipal Baltazar Dias (Funchal)',
    links: [
      { eventLink: 'https://ticketline.sapo.pt/evento/push-it-online-68467' },
      { ticketLink: 'https://ticketline.sapo.pt/evento/push-it-online-68467' },
      { videoLink: 'http://' },
      { facebookLink: 'http://' },
      { umuvLink: 'http://' },
    ],
  },
  {
    what: 'KUTXI KUTXI KUTXI',
    when: '16 Outubro 2022',
    where: 'Pequeno Auditório do Centro Olga Cadaval, Sintra, Portugal',
    links: [{ eventLink: '' }, { ticketLink: '' }, { videoLink: '' }, { facebookLink: '' }, { umuvLink: '' }],
  },
  {
    what: 'KUTXI KUTXI KUTXI',
    when: '13 e 14 Outubro 2022',
    where: 'Pequeno Auditório do Centro Olga Cadaval, Sintra, Portugal (sessions only for schools in Sintra)',
    links: [{ eventLink: '' }, { ticketLink: '' }, { videoLink: '' }, { facebookLink: '' }, { umuvLink: '' }],
  },
];
