import React, { useRef } from 'react';
import { repertoryData } from '../data/repertory';
import { Outlet, useNavigate, useMatches } from 'react-router-dom';

const Repertory = () => {
  const navigate = useNavigate();

  let matches = useMatches();
  let pathname = matches.filter(match => Boolean(match.handle?.path))[0]?.handle.path;
  const titleRef = useRef();

  function handleTitleClick(to) {
    titleRef.current.scrollIntoView({ behavior: 'smooth' });
    navigate(to);
  }

  return (
    <div className="flex bg-zinc-100  flex-col py-20 p-20 justify-start w-full h-full">
      <div className="">
        <div className="flex gap-10 flex-wrap justify-center">
          {repertoryData.map(rep => (
            <div key={rep.title} className="min-w-fit">
              <img
                src={rep.img}
                className="hover:scale-105 transition ease-in cursor-pointer object-cover h-48 w-80 "
                onClick={() => handleTitleClick(rep.link_to)}
                alt={rep.title}
              />
              <h2
                className={`py-2 cursor-pointer p-2 ${pathname === rep.link_to ? 'text-white' : 'text-golden'} ${
                  pathname === rep.link_to ? 'bg-golden' : 'bg-none'
                }`}
                onClick={() => handleTitleClick(rep.link_to)}
                ref={titleRef}
              >
                {rep.title}
                <span className={`px-2 text-sm ${pathname === rep.link_to ? 'text-white' : 'text-golden'}`}>
                  {' '}
                  ({rep.date})
                </span>
              </h2>
            </div>
          ))}
        </div>
      </div>
      <div className="py-20">
        <Outlet />
      </div>
    </div>
  );
};

export default Repertory;
