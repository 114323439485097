import ReactPlayer from 'react-player';

export default function QuemRoubouONatal() {
  return (
    <div className="text-center">
      <h1 className="text-golden text-4xl py-20">Quem Roubou o Natal? (Who Stole Christmas?)</h1>

      <div className="flex flex-col leading-relaxed items-center">
        <p className="leading-relaxed pt-20">SINOPSE:</p>
        <p className="leading-relaxed p-20 text-2xl xl:w-2/3 lg:w-full sm:w-full">
          Grunho is a cynical character from an imaginary world who struggles to deal with his emotions. Traumatized by
          his past experiences, he comes across as arrogant and distant toward others. The joy of Christmas reminds him
          of very sad times, so he decides to ruin Christmas for all the children. However, with the help of beloved,
          super-enthusiastic Christmas characters like little Dina, little Dony, Helena the Reindeer, Celso the Elf, and
          Rex the Dog, Grunho will reconcile with the Christmas spirit and discover the meaning of sharing and
          friendship.
        </p>
        <p>A show full of color, movement, dance, action, music, and suspense for the whole family!</p>
        <ReactPlayer
          url="https://www.youtube.com/shorts/VnH0tRX6sZU"
          controls
          width="30%"
          height="600px"
          className="py-10"
        />
      </div>

      <div className="grid xl:grid-cols-3 sm:grid-cols-1 gap-10 justify-center items-center mt-20 mb-20">
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1738491407/Sublime%20Dance%20Company/REPERTORY/Who%20Stole%20Christmas/20241206_OVN_mary_44.jpg"
          className="w-full"
          alt="Quem Roubou o Natal"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1738491408/Sublime%20Dance%20Company/REPERTORY/Who%20Stole%20Christmas/20241206_OVN_mary_45.jpg"
          className="w-full"
          alt="Quem Roubou o Natal"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1738491416/Sublime%20Dance%20Company/REPERTORY/Who%20Stole%20Christmas/20241212_OVN_nunoefconceicao_00127.jpg"
          className="w-full"
          alt="Quem Roubou o Natal"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1738491411/Sublime%20Dance%20Company/REPERTORY/Who%20Stole%20Christmas/20241206_OVN_mary_50.jpg"
          className="w-full"
          alt="Quem Roubou o Natal"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1738491408/Sublime%20Dance%20Company/REPERTORY/Who%20Stole%20Christmas/20241206_OVN_mary_43.jpg"
          className="w-full"
          alt="Quem Roubou o Natal"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1738491406/Sublime%20Dance%20Company/REPERTORY/Who%20Stole%20Christmas/Co%CC%81pia_de_20241206_OVN_nunoefconceicao_00125.jpg"
          className="w-full"
          alt="Quem Roubou o Natal"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1738491400/Sublime%20Dance%20Company/REPERTORY/Who%20Stole%20Christmas/20241212_OVN_nunoefconceicao_00196-Enhanced-NR.jpg"
          className="w-full"
          alt="Quem Roubou o Natal"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1738491400/Sublime%20Dance%20Company/REPERTORY/Who%20Stole%20Christmas/20241212_OVN_nunoefconceicao_00011.jpg"
          className="w-full"
          alt="Quem Roubou o Natal"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1738491400/Sublime%20Dance%20Company/REPERTORY/Who%20Stole%20Christmas/20241212_OVN_nunoefconceicao_00208.jpg"
          className="w-full"
          alt="Quem Roubou o Natal"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1738491402/Sublime%20Dance%20Company/REPERTORY/Who%20Stole%20Christmas/20241206_OVN_nunoefconceicao_02323.jpg"
          className="w-full"
          alt="Quem Roubou o Natal"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1738491401/Sublime%20Dance%20Company/REPERTORY/Who%20Stole%20Christmas/20241212_OVN_nunoefconceicao_00048.jpg"
          className="w-full"
          alt="Quem Roubou o Natal"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1738491404/Sublime%20Dance%20Company/REPERTORY/Who%20Stole%20Christmas/20241206_OVN_nunoefconceicao_00072.jpg"
          className="w-full"
          alt="Quem Roubou o Natal"
        />
      </div>
      <div className="text-center">
        <div className="leading-10">
          <h1 className="text-golden text-4xl py-10">Quem Roubou O Natal? (Who Stole Xhristmas?)</h1>
          <p>
            <span className="text-golden px-2">Artistic Direction:</span> Diana Seabra
          </p>
          <p>
            <span className="text-golden px-2">Choreography: </span>Diana Seabra
          </p>

          <p>
            <span className="text-golden px-2">Assistant to the Direction:</span>Olsi Gjeci
          </p>
          <p>
            <span className="text-golden px-2">Co-Production: </span>Sublime Dance Company and Óbidos Criativa
          </p>

          <p>
            <span className="text-golden px-2">Text and Voice:</span> Diana Seabra
          </p>
          <br />
          <p>
            <span className="text-golden px-2">Performers and Characters:</span>
          </p>
          <p>Miguel Santos (Mr. Grunho)</p>
          <p>David Reis (Rex the Dog)</p>
          <p>Sancha Fonseca (Dina the Girl)</p>
          <p>Nuno Velosa (Doni the Boy)</p>
          <p>Aleksandra Demina (Celso the Elf)</p>
          <p>Amália Santos (Helena the Reindeer)</p>
          <p>
            <span className="text-golden px-2">Music Editing: </span> Nelson Seabra
          </p>
          <p>
            <span className="text-golden px-2">Costumes: </span> Rosário Balbi
          </p>
          <p>
            <span className="text-golden px-2">Set Design: </span>Rita Reis (Óbidos Criativa)
          </p>
          <p>
            <span className="text-golden px-2">Lighting: </span> Óbidos Criativa
          </p>
          <p>
            <span className="text-golden px-2">Partnerships: </span>Feel it Dance Company, Bispos Tecidos, Loja do
            Hélder
          </p>
        </div>
      </div>
    </div>
  );
}
