const TheFluidityOfThings = () => {
  return (
    <div className="text-center">
      <div className="leading-10">
        <h1 className="text-golden text-4xl py-10">The Fluidity Of Things</h1>
        <p>Performers: Diana Seabra, Pedro Carvalho, Joana Marques, Olsi Gjeci, </p>
        Marcelo <p>Magalhães</p>
        <p>Choreography: Olsi Gjeci</p>
        <p>Music: Murcof, Steffani, Pergolesi, Origamibiro, John Surman, Amsterdam Klezmer Band, Monolake, Apparat,</p>
        <p>Costume Design: Vanessa Pessoa, Jesus Roriz</p>
        <p>Lighting Designer: StagePlot</p>
        <p>Premiere:</p>
        <p>April 27, 2019</p>
        <p>Event: Junta-te à dança</p>
        <p>Place: Mercado De Benfica R. João Frederico Ludovice, Benfica, Lisboa,Portugal</p>
      </div>

      <div className="grid xl:grid-cols-3 sm:grid-cols-1 gap-10 justify-center items-center mt-20 mb-20">
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665439690/Sublime%20Dance%20Company/REPERTORY/The%20Fluidity%20Of%20Things/FOT_1.jpg"
          className="w-full"
          alt="The Fluidity Of Things show  - falling - out of balance"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665439689/Sublime%20Dance%20Company/REPERTORY/The%20Fluidity%20Of%20Things/FOT_4.jpg"
          className="w-full"
          alt="The Fluidity Of Things show  - group jump"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665439689/Sublime%20Dance%20Company/REPERTORY/The%20Fluidity%20Of%20Things/FOT_2.jpg"
          className="w-full"
          alt="The Fluidity Of Things show  - lifting/flying"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665439689/Sublime%20Dance%20Company/REPERTORY/The%20Fluidity%20Of%20Things/FOT_6.jpg"
          className="w-full"
          alt="The Fluidity Of Things show  - solo on the floor"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665439690/Sublime%20Dance%20Company/REPERTORY/The%20Fluidity%20Of%20Things/FOT_3.jpg"
          className="w-full"
          alt="The Fluidity Of Things show  - blue duet"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665439689/Sublime%20Dance%20Company/REPERTORY/The%20Fluidity%20Of%20Things/FOT_5.jpg"
          className="w-full"
          alt="The Fluidity Of Things show  - couples"
        />
      </div>

      <p className="leading-10">
        A piece that embodies the idea that in life everything flows. Things happen one after the other and the human
        being plays, gets distracted and grows in that fluidity. Events flow and influence each other. As a result, a
        million facts conjugate themselves in infinite different forms. The ilusion of control may blind us from our own
        growth, for our only control lies on how we receive and deal with what happens to us. Illusion is attractive,
        seductive, persistent and perseverant. Our action is movement and movement is dance.
      </p>
      <p className="text-center leading-10 py-20">
        THE FLUDITY OF THINGS dances the action of 5 bodies in space, time, interelationships without forgetting the
        infinite possibilities for the same event. At the same time, other bodies defy the audience with the idea of
        stillness. What scares us the most? Fluidity or Stillness? Do we want to keep moving or do we want to stop?
      </p>

      <h3 className="py-2 text-golden text-2xl mt-40">This project was supported by</h3>

      <div className="flex justify-center py-20">
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665441114/Sublime%20Dance%20Company/logos/estudios-victor-cordon-logo-black.png"
          alt="estudios-victor-cordon - suppport"
          className="object-scale-down h-48 w-96"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665441118/Sublime%20Dance%20Company/logos/stage-plot-logo.png"
          alt="support - stage plot"
          className="object-scale-down h-48 w-96"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665441122/Sublime%20Dance%20Company/logos/logo_oeiras_dance_academy.png"
          alt="support - oeiras dance academy"
          className="object-scale-down h-48 w-96"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665441121/Sublime%20Dance%20Company/logos/umuv-circle.png"
          alt="support - UMUV.WORLD"
          className="object-scale-down h-48 w-96"
        />
      </div>
    </div>
  );
};

export default TheFluidityOfThings;
