import ReactPlayer from 'react-player';

const SixtyFourTimesPossibilities = () => {
  return (
    <div className="flex justify-center flex-col items-center text-center leading-10">
      <h1 className="text-golden text-4xl">64x Possibilities</h1>
      <h4 className="text-golden text-xl">
        The dance emerges from a deep desire to investigate new movement possibilities bound by time and space
        limitations.
      </h4>
      <div className="py-10"></div>
      <p>
        <span className="text-golden px-2">Performers:</span>Diana Seabra, Rui Peixoto, Catarina Albano, Renato Gomes,
        Tatiana Verissímo
      </p>
      <p>
        <span className="text-golden px-2">Choreography:</span>Olsi Gjeci & Diana Seabra
      </p>
      <p>
        <span className="text-golden px-2">Music:</span>Murcof, Nosaj Thing, Arvo Part, Jacaszek, Monolake
      </p>
      <p>Costumes: Vanessa Pessoa</p>
      <p>Lighting Designer: Carlos Ramos</p>
      <p>
        <span className="text-golden px-2">Premiere: June 20, 2014</span>
      </p>
      <p>
        <span className="text-golden px-2">Place:</span>Centro Cultural De Belem. Lisbon, Portugal
      </p>
      <div className="grid xl:grid-cols-2 sm:grid-cols-1 gap-10 justify-center items-center mt-20 mb-20">
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665444795/Sublime%20Dance%20Company/REPERTORY/64x%20Possibilities/64x_3.jpg  "
          className="w-full"
          alt=""
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665444794/Sublime%20Dance%20Company/REPERTORY/64x%20Possibilities/64x_1.jpg"
          className="w-full"
          alt=""
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665444795/Sublime%20Dance%20Company/REPERTORY/64x%20Possibilities/64x_2.jpg"
          className="w-full"
          alt=""
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665444795/Sublime%20Dance%20Company/REPERTORY/64x%20Possibilities/64x_4.jpg"
          className="w-full"
          alt=""
        />
      </div>
      <ReactPlayer url="https://www.youtube.com/watch?v=AOcQpJ2pimQ" controls />

      <div className="flex justify-center flex-col w-1/2">
        <h4 className="text-golden text-xl leading-10">
          Started out as a research study, this piece is inspired by the two-player strategy board game, chess.
        </h4>
        <h4 className="text-golden text-xl leading-10">
          The idea came to life during one of Olsi's dedicated nights playing chess. The visuals ascended in his mind
          during the game, inspired this project. The choreographic process in this piece is grounded by the rules of
          chess. The stage is transformed into a chessboard itself. Movement patterns are exhaustively explored and
          created by experimenting with each pieces' movement capabilities and limitations.
        </h4>
        <h4 className="text-golden text-xl leading-10">
          The movement patterns are then selected to abstractly mirror the logic behind the game of chess. The further
          development of these ideas gave rise to working with other elements, such as, creating physical
          boundaries/obstacles on stage, namely the 3D extensions of the black & white squares. Walls grow between the
          dancers, turning the space into a labyrinth which challenges the dancers to find a collective awareness within
          their own ndividuality.
        </h4>
      </div>
    </div>
  );
};

export default SixtyFourTimesPossibilities;
