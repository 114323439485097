import React from 'react';
import ReactPlayer from 'react-player';
import { events, events2023, events2024 } from '../data/calendar';

const Calendar = () => {
  return (
    <div className="bg-zinc-100 w-full flex flex-col py-20 p-20 justify-start ">
      {' '}
      <div className="mt-20">
        <h1 className="text-4xl text-golden">2024</h1>
        {/* <p className="text-2xl text-golden py-6"></p>
         */}
        {events2024.map(event => (
          <p key={event?.what} className="text-gray-900 mt-20 leading-9">
            <span className="text-golden">What: </span>
            {event.what}
            <br />
            <span className="text-golden">When: </span>
            {event.when}
            <br />
            <span className="text-golden">Where: </span>

            {event.links[0].eventLink.length ? (
              <a href={event.links[0].eventLink} target="_blank" className="underline" rel="noopener noreferrer">
                {event.where}
              </a>
            ) : (
              event.where
            )}
            <div>{event?.about || ''}</div>
          </p>
        ))}{' '}
      </div>
      <div className="mt-20">
        <h1 className="text-4xl text-golden">2023</h1>
        {/* <p className="text-2xl text-golden py-6"></p>
         */}
        {events2023.map(event => (
          <p className="text-gray-900 mt-20 leading-9">
            <span className="text-golden">What: </span>
            {event.what}
            <br />
            <span className="text-golden">When: </span>
            {event.when}
            <br />
            <span className="text-golden">Where: </span>

            {event.links[0].eventLink.length ? (
              <a href={event.links[0].eventLink} target="_blank" className="underline" rel="noopener noreferrer">
                {event.where}
              </a>
            ) : (
              event.where
            )}
          </p>
        ))}{' '}
      </div>
      <div className="mt-20">
        <h1 className="text-4xl text-golden">2022</h1>
      </div>
      <img
        src="https://res.cloudinary.com/umuv/image/upload/v1666632211/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/PIO-funchal.jpg"
        className="w-1/4 mt-10"
        alt="push-it-online-cal-upcoming-show"
      />
      {events.map(event => (
        <p className="text-gray-900 mt-20 leading-9">
          <span className="text-golden">What: </span>
          {event.what}
          <br />
          <span className="text-golden">When: </span>
          {event.when}
          <br />
          <span className="text-golden">Where: </span>

          {event.links[0].eventLink.length ? (
            <a href={event.links[0].eventLink} target="_blank" className="underline" rel="noopener noreferrer">
              {event.where}
            </a>
          ) : (
            event.where
          )}
        </p>
      ))}{' '}
      <div className="py-10"></div>
      <ReactPlayer url="https://youtu.be/34Gh0db6cGY" controls />
      <div className="py-10"></div>
      <div className="flex gap-10">
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1664704884/Sublime%20Dance%20Company/REPERTORY/Kutxi%20Kutxi/kutxi-rollup-poster.jpg"
          className="w-1/4"
          alt="kutxi-poster-cal-upcoming-show"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1664705172/Sublime%20Dance%20Company/REPERTORY/Kutxi%20Kutxi/kutxi-poster.jpg"
          className="w-2/4"
          alt="kutxi-poster-cal-upcoming-show"
        />
      </div>
      <div className="py-20 w-3/4"></div>
    </div>
  );
};

export default Calendar;
